import React, { useEffect } from 'react'
import styled from 'styled-components'
import {IUser} from './IUser'
import {
    Container as MyMessageContainer,
    Content,
    Wrapper as MyMessageWrapper, /*Timestamp,*/
    TimestampContainer as MyMessageTimestampContainer,
    Background,
    Timestamp
} from './OutGoingMessage'
import placeholderProfilePNG from './profile.webp'
import {MessageType} from "./IMessage";

type Props = {
    text?: string,
    image?: string,
    user?: IUser,
    themeColor?: string
    showAvatar?: boolean
    showHeader?: boolean
    time?: string;
    // determines whether its the last message in the group of incoming messages
    last?: boolean
    //determines whether its the only message in the group of incoming messages
    single?: boolean
    messageType: MessageType;
    onDownloadFile?: () => void;
}

const MessageContainer = styled(MyMessageContainer)`
    margin-left: 0px;
    box-sizing: border-box;
    margin-bottom: 0px;
`


const Wrapper = styled(MyMessageWrapper)`
justify-content: start;
align-items: flex-end;
`

const DPContainer = styled.div`
    width: 32px;
    height: 32px;
    margin-left: 10px;
    box-sizing: border-box;
`
const DisplayPicture = styled.img`
    width: 32px;
    height: 32px;
    border-radius: 9999px;
    box-sizing: border-box;
    border-width: 2px;
    //border-color: rgb(255 255 255);
    object-fit: cover;
`



const Name = styled.div`
text-align:left;
vertical-align:text-top;
font-size:14px;
align-self:flex-start;
line-height:auto;
color: val(--primary-content);
font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
font-weight: 500;
`

const TextWrapper = styled.div`
margin-left:8px;
box-sizing: border-box;
`

const TimestampContainer = styled(MyMessageTimestampContainer)`
margin-left: 8px;
margin-bottom: -2px;
margin-right: 0px;
box-sizing: border-box;
`

const OtherMessageBackground = styled(Background)`
    opacity: 0.8;
`

const HeaderContainer = styled.div`
 display: flex; 
 align-items: "center";
 margin-top: 16px;
 margin-bottom: 6px;
 `

const ImageContainer = styled.div`
    width: 100%;
    margin: 8px;
    position: relative;
    cursor: pointer;

`

const Image = styled.img`
    width: 100%;
 `
const AttachmentContainer = styled.div`
left: 0px;
position: relative;
    padding-left:16px;
    padding-right:16px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
height:100%;
opacity:1;
box-sizing: border-box;
`

export default function IncomingMessage({
                                            text,
                                            image,
                                            user,
                                            showAvatar,
                                            showHeader,
                                            last,
                                            single,
                                            messageType,
                                            onDownloadFile,
                                            time,
                                            themeColor = '#6ea9d7' }: Props) {

    const [avatar, setAvatar] = React.useState<string>(placeholderProfilePNG)

    useEffect(() => {
        if (user?.avatar && user.avatar.trim().length > 0) {
            setAvatar(user.avatar)
        }
    }, [user])


    return (
        <Wrapper
            className='fade-animation'
        >
            <DPContainer>
                {showAvatar &&

                    <DisplayPicture
                        onError={() => {
                            setAvatar(placeholderProfilePNG)
                        }}
                        src={avatar}
                    />}
            </DPContainer>

            <TextWrapper>
                {showHeader &&
                    <HeaderContainer>
                        <Name>{user?.name}</Name>


                        <TimestampContainer>
                            <Timestamp >{time} </Timestamp>
                        </TimestampContainer>
                    </HeaderContainer>
                }

                <div style={{ display: "flex" }}>
                    <MessageContainer>
                        <OtherMessageBackground
                            borderTopRight
                            borderBottomRight={!last ? true : false}
                            borderBottomLeft={last || single ? true : false}
                            bgColor={themeColor} />

                        {image ?
                            <ImageContainer onClick={onDownloadFile}>
                                <Image src={image} />
                            </ImageContainer>
                            : messageType === MessageType.file ?
                                <AttachmentContainer onClick={onDownloadFile}>
                                    <Content>{text}</Content>
                                    <svg
                                        fill={"#8e99a4"}
                                        width="24"
                                        height="24"
                                        viewBox="0 0 32 32"
                                        xmlns="http://www.w3.org/2000/svg">

                                        <g id="SVGRepo_bgCarrier" strokeWidth="0" />

                                        <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" />

                                        <g id="SVGRepo_iconCarrier"> <title>paperclip</title> <path d="M29.131 15.262c-0.226-0.227-0.54-0.368-0.886-0.368-0.344 0-0.656 0.139-0.882 0.364l-11.003 10.959c-1.163 1.312-2.853 2.134-4.735 2.134-1.812 0-3.446-0.763-4.598-1.985l-0.003-0.003c-1.236-1.157-2.006-2.799-2.006-4.62 0-1.872 0.813-3.553 2.105-4.711l0.006-0.005 12.001-12c0.769-0.857 1.879-1.394 3.116-1.394s2.348 0.537 3.113 1.391l0.003 0.004c0.858 0.768 1.395 1.879 1.395 3.115s-0.536 2.345-1.389 3.109l-0.004 0.003-11.081 10.996c-1.463 1.438-2.912 1.273-3.698 0.473s-0.926-2.252 0.544-3.695l8.001-8.002c0.228-0.226 0.369-0.54 0.369-0.886 0-0.69-0.56-1.25-1.25-1.25-0.347 0-0.66 0.141-0.887 0.369l-7.992 7.993c-1.141 0.917-1.865 2.313-1.865 3.877 0 1.291 0.493 2.467 1.301 3.35l-0.003-0.004c0.887 0.841 2.089 1.357 3.411 1.357 1.537 0 2.91-0.698 3.821-1.795l0.007-0.008 11.090-11.004c1.307-1.226 2.121-2.963 2.121-4.891 0-0.111-0.003-0.222-0.008-0.332l0.001 0.016c-0.131-1.796-0.914-3.388-2.112-4.558l-0.001-0.001c-1.172-1.199-2.764-1.983-4.537-2.114l-0.023-0.001c-0.089-0.004-0.194-0.007-0.299-0.007-1.933 0-3.676 0.814-4.906 2.118l-0.003 0.003-12.002 12.002c-1.751 1.615-2.845 3.922-2.845 6.483 0 2.514 1.053 4.781 2.741 6.386l0.004 0.004c1.635 1.654 3.894 2.688 6.394 2.721l0.006 0c2.554-0.041 4.845-1.135 6.463-2.866l0.005-0.005 11-10.955c0.227-0.226 0.367-0.539 0.367-0.885 0-0.345-0.14-0.657-0.365-0.883l0 0z" /> </g>

                                    </svg>
                                </AttachmentContainer> :
                            <Content>{text}</Content>
                        }
                    </MessageContainer>
                </div>

            </TextWrapper>
        </Wrapper>
    )
}

