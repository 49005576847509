import React from "react";
import MessageList from "./MessageList";
import MessageInput from "./MessageInput";
import MessageHeader from "./MessageHeader";
import { IMessage } from "./IMessage";
import styled from "styled-components";
import useCheckIsMobile from "./useCheckIsMobile";

export type Props = {
  onBack?: () => void;
  showBack?: boolean;
  header?: string;
  mobileView?: boolean;
  onSendMessage?: (text: string) => void;
  themeColor?: string;
  onScrollToTop?: () => void;
  sendMessageLoading?: boolean;
  loading?: boolean;
  currentUserId?: string;
  messages?: IMessage[];
  showTypingIndicator?: boolean;
  typingIndicatorContent?: string;
  onStartTyping?: () => void;
  onEndTyping?: () => void;
  onAttachClick?: () => void;
};

const Container = styled.div<{ mobile: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: absolute;
  margin-bottom: 0;
  flex: 1;
  padding-left: 0;
  ${({ mobile }) =>
    mobile
      ? `
padding-right: 0px;
`
      : ""}
  border-radius: 16px;
`;

export default function MessageContainer(props: Props) {
  const isMobile = useCheckIsMobile();

  return (
    <Container mobile={props.mobileView || isMobile}>
      <MessageList {...props} />

      {!props.loading && <MessageInput {...props} />}
    </Container>
  );
}
