import React from 'react'
import {IUser} from './IUser';
import OutgoingMessage from './OutGoingMessage'
import IncomingMessage from './IncomingMessage'
import {MessageType} from "./IMessage";


export type Props = {
    text?: string,
    image?: string,
    loading?: boolean
    themeColor?: string
    messageType: MessageType;
    type?: "incoming" | "outgoing"
    user?: IUser
    showAvatar?: boolean
    showHeader?: boolean
    // determines whether its the last message in the group of outgoing or incoming
    last?: boolean
    //determines whether its the only message in the group of outgoing or incoming
    single?: boolean
    clusterFirstMessage?: boolean
    clusterLastMessage?: boolean
    onDownloadClick?: () => void;
    time?: string;
};


export default function Message({
                                    text,
                                    image,
                                    themeColor,
                                    loading,
                                    type = "outgoing",
                                    user,
                                    showAvatar,
                                    showHeader,
                                    last,
                                    single,
                                    clusterFirstMessage,
                                    clusterLastMessage,
                                    messageType,
                                    onDownloadClick,
                                    time
                                }: Props) {
    return (
        type === "outgoing" ?
            <OutgoingMessage
                themeColor={themeColor}
                loading={loading}
                text={text}
                image={image}
                last={last}
                single={single}
                clusterFirstMessage={clusterFirstMessage}
                clusterLastMessage={clusterLastMessage}
                messageType={messageType}
                onDownloadFile={onDownloadClick}
                time={time}
            />

            :

            <IncomingMessage
                showAvatar={showAvatar}
                themeColor={themeColor}
                text={text}
                image={image}
                user={user}
                showHeader={showHeader}
                last={last}
                single={single}
                messageType={messageType}
                onDownloadFile={onDownloadClick}
                time={time}
            />

    )
}

