import * as React from 'react'
import styled from 'styled-components'
import {Loading} from './loading'
import {MessageType} from "./IMessage";

type Props = {
    text?: string,
    image?: string,
    loading?: boolean
    themeColor?: string
    time?: string;
    // determines whether its the last message in the group of outgoing messages
    last?: boolean
    //determines whether its the only message in the group of outgoing messages
    single?: boolean
    clusterFirstMessage?: boolean
    clusterLastMessage?: boolean
    messageType: MessageType;
    onDownloadFile?: () => void;

}

export const Wrapper = styled.div<{ firstMessage?: boolean, lastMessage?: boolean }>`
    display:flex;
    justify-content: end;
    margin-right: 10px;
    margin-top: ${({ firstMessage }) => firstMessage ? "16px" : "6px"};
    position: relative;
    box-sizing: border-box;
    margin-bottom: ${({ lastMessage }) => lastMessage ? "16px" : "6px"};
`



export const Container = styled.div`
max-width:272px;
margin-left: 10px;
display:flex;
flex-direction:row;
justify-content:flex-end;
align-items:flex-end;
gap:10px;
position:relative;
box-sizing: border-box;

`
export const Background = styled.div<{
    bgColor: string,
    borderTopRight?: boolean,
    borderTopLeft?: boolean,
    borderBottomLeft?: boolean,
    borderBottomRight?: boolean,
}>`
    position: absolute;
    width: 100%;
    height: 100%;
    background-color:${({ bgColor }) => bgColor};

    border-top-left-radius: ${({ borderTopLeft }) => borderTopLeft ? "8px" : "2px"};
    border-top-right-radius: ${({ borderTopRight }) => borderTopRight ? "8px" : "2px"};
    border-bottom-left-radius: ${({ borderBottomLeft }) => borderBottomLeft ? "8px" : "2px"};
    border-bottom-right-radius: ${({ borderBottomRight }) => borderBottomRight ? "8px" : "2px"};


`

export const Content = styled.div`
text-align:left;
vertical-align:text-top;
font-size:14px;
align-self:flex-start;
line-height:auto;
color:#000000;
font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
padding-left:16px;
padding-right:16px;
padding-top:8px;
padding-bottom:8px;
position: relative;
box-sizing: border-box;

`
const AttachmentContainer = styled.div`
  left: 0px;
  position: relative;
  padding-left:16px;
  padding-right:16px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height:100%;
  opacity:1;
  box-sizing: border-box;
`

export const TimestampContainer = styled.div`
margin-right: 4px;
margin-bottom: 4px;
display:flex;
flex-direction:row;
justify-content:flex-end;
align-items:center;
box-sizing: border-box;
position:relative;
`

export const Timestamp = styled.div`
text-align:right;
vertical-align:text-top;
font-size:12px;
align-self:flex-start;
line-height:auto;
color:#7a7a7a;
font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
`


const LoadingContainer = styled.div`
    position: absolute;
    height: 100%;
    display: flex;
    align-items: center;
`

const ImageContainer = styled.div`
    width: 100%;
    margin: 8px;
    position: relative;
    cursor: pointer;

`

const Image = styled.img`
    width: 100%;
 `

export default function MyMessage({
                                      text,
                                      image,
                                      themeColor = '#6ea9d7',
                                      loading,
                                      last,
                                      single,
                                      clusterFirstMessage,
                                      clusterLastMessage,
                                      messageType,
                                      onDownloadFile,
                                      time,
                                  }: Props) {
    return (
        <Wrapper
            lastMessage={clusterLastMessage}
            firstMessage={clusterFirstMessage}
            className='fade-animation'
        >
            <div>
                <TimestampContainer>
                <Timestamp>{time}</Timestamp>
            </TimestampContainer>

                <Container>
                    <Background
                        borderTopLeft
                        borderBottomLeft
                        borderBottomRight={last ? true : false}
                        borderTopRight={!last && single ? true : false}
                        bgColor={themeColor} />

                    {image ?
                        <ImageContainer onClick={onDownloadFile}>
                            <Image src={image} />
                        </ImageContainer>
                        : messageType === MessageType.file ?
                            <AttachmentContainer onClick={onDownloadFile}>
                                <Content>{text}</Content>
                                <svg
                                fill={"#8e99a4"}
                                width="24"
                                height="24"
                                viewBox="0 0 32 32"
                                xmlns="http://www.w3.org/2000/svg">

                                <g id="SVGRepo_bgCarrier" strokeWidth="0" />

                                <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" />

                                <g id="SVGRepo_iconCarrier"> <title>paperclip</title> <path d="M29.131 15.262c-0.226-0.227-0.54-0.368-0.886-0.368-0.344 0-0.656 0.139-0.882 0.364l-11.003 10.959c-1.163 1.312-2.853 2.134-4.735 2.134-1.812 0-3.446-0.763-4.598-1.985l-0.003-0.003c-1.236-1.157-2.006-2.799-2.006-4.62 0-1.872 0.813-3.553 2.105-4.711l0.006-0.005 12.001-12c0.769-0.857 1.879-1.394 3.116-1.394s2.348 0.537 3.113 1.391l0.003 0.004c0.858 0.768 1.395 1.879 1.395 3.115s-0.536 2.345-1.389 3.109l-0.004 0.003-11.081 10.996c-1.463 1.438-2.912 1.273-3.698 0.473s-0.926-2.252 0.544-3.695l8.001-8.002c0.228-0.226 0.369-0.54 0.369-0.886 0-0.69-0.56-1.25-1.25-1.25-0.347 0-0.66 0.141-0.887 0.369l-7.992 7.993c-1.141 0.917-1.865 2.313-1.865 3.877 0 1.291 0.493 2.467 1.301 3.35l-0.003-0.004c0.887 0.841 2.089 1.357 3.411 1.357 1.537 0 2.91-0.698 3.821-1.795l0.007-0.008 11.090-11.004c1.307-1.226 2.121-2.963 2.121-4.891 0-0.111-0.003-0.222-0.008-0.332l0.001 0.016c-0.131-1.796-0.914-3.388-2.112-4.558l-0.001-0.001c-1.172-1.199-2.764-1.983-4.537-2.114l-0.023-0.001c-0.089-0.004-0.194-0.007-0.299-0.007-1.933 0-3.676 0.814-4.906 2.118l-0.003 0.003-12.002 12.002c-1.751 1.615-2.845 3.922-2.845 6.483 0 2.514 1.053 4.781 2.741 6.386l0.004 0.004c1.635 1.654 3.894 2.688 6.394 2.721l0.006 0c2.554-0.041 4.845-1.135 6.463-2.866l0.005-0.005 11-10.955c0.227-0.226 0.367-0.539 0.367-0.885 0-0.345-0.14-0.657-0.365-0.883l0 0z" /> </g>

                            </svg>
                            </AttachmentContainer> :
                        <Content>{text}</Content>
                    }


                    {loading && <LoadingContainer> <Loading /> </LoadingContainer>}

                </Container>

            </div>
        </Wrapper>
    )
}

