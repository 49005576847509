/*
Copyright 2022 New Vector Ltd

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/

import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { usePreventScroll } from "@react-aria/overlays";
import useMeasure from "react-use-measure";
import { ResizeObserver } from "@juggle/resize-observer";
import { MatrixClient } from "matrix-js-sdk/src/client";
import { RoomMember } from "matrix-js-sdk/src/models/room-member";
import {
  CallsByUserAndDevice,
  GroupCall,
  GroupCallEvent,
} from "matrix-js-sdk/src/webrtc/groupCall";
import { CallFeed } from "matrix-js-sdk/src/webrtc/callFeed";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { JoinRule } from "matrix-js-sdk/src/@types/partials";
import type { IWidgetApiRequest } from "matrix-widget-api";
import styles from "./InCallView.module.css";
import {
  ChatButton,
  HangupButton,
  MicButton,
  ScreenshareButton,
  VideoButton,
} from "../button";
import {
  Header,
  LeftNav,
  RoomHeaderInfo,
  VersionMismatchWarning,
} from "../Header";
import {
  ChildrenProperties,
  useVideoGridLayout,
  VideoGrid,
} from "../video-grid/VideoGrid";
import { VideoTileContainer } from "../video-grid/VideoTileContainer";
import { GroupCallInspector } from "./GroupCallInspector";
import { OverflowMenu } from "./OverflowMenu";
import { Avatar } from "../Avatar";
import { useRageshakeRequestModal } from "../settings/submit-rageshake";
import { RageshakeRequestModal } from "./RageshakeRequestModal";
import { useMediaHandler } from "../settings/useMediaHandler";
import {
  useNewGrid,
  useShowInspector,
  useSpatialAudio,
} from "../settings/useSetting";
import { useModalTriggerState } from "../Modal";
import { useAudioContext } from "../video-grid/useMediaStream";
import { useFullscreen } from "../video-grid/useFullscreen";
import { PosthogAnalytics } from "../analytics/PosthogAnalytics";
import { ElementWidgetActions, widget } from "../widget";
import { useJoinRule } from "./useJoinRule";
import { useUrlParams } from "../UrlParams";
import { usePrefersReducedMotion } from "../usePrefersReducedMotion";
import { ParticipantInfo } from "./useGroupCall";
import { TileDescriptor } from "../video-grid/TileDescriptor";
import { AudioSink } from "../video-grid/AudioSink";
import { NewVideoGrid } from "../video-grid/NewVideoGrid";
import { OTelGroupCallMembership } from "../otel/OTelGroupCallMembership";
import MessageContainer from "../message/MessageContainer";
import { IMessage, MessageType } from "../message/IMessage";
import { CallEvent } from "matrix-js-sdk/src/webrtc/call";
import { v4 as uuidv4 } from "uuid";
import useCheckIsMobile from "../message/useCheckIsMobile";

const canScreenshare = "getDisplayMedia" in (navigator.mediaDevices ?? {});
// There is currently a bug in Safari our our code with cloning and sending MediaStreams
// or with getUsermedia and getDisplaymedia being used within the same session.
// For now we can disable screensharing in Safari.
const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

interface Props {
  client: MatrixClient;
  groupCall: GroupCall;
  participants: Map<RoomMember, Map<string, ParticipantInfo>>;
  roomName: string;
  avatarUrl: string;
  microphoneMuted: boolean;
  localVideoMuted: boolean;
  toggleLocalVideoMuted: () => void;
  toggleMicrophoneMuted: () => void;
  toggleScreensharing: () => void;
  setMicrophoneMuted: (muted: boolean) => void;
  userMediaFeeds: CallFeed[];
  activeSpeaker: CallFeed | null;
  onLeave: () => void;
  isScreensharing: boolean;
  screenshareFeeds: CallFeed[];
  roomIdOrAlias: string;
  unencryptedEventsFromUsers: Set<string>;
  hideHeader: boolean;
  otelGroupCallMembership: OTelGroupCallMembership;
}

export function InCallView({
  client,
  groupCall,
  participants,
  roomName,
  avatarUrl,
  microphoneMuted,
  localVideoMuted,
  toggleLocalVideoMuted,
  toggleMicrophoneMuted,
  setMicrophoneMuted,
  userMediaFeeds,
  activeSpeaker,
  onLeave,
  toggleScreensharing,
  isScreensharing,
  screenshareFeeds,
  roomIdOrAlias,
  unencryptedEventsFromUsers,
  hideHeader,
  otelGroupCallMembership,
}: Props) {
  const { t } = useTranslation();
  usePreventScroll();
  const joinRule = useJoinRule(groupCall.room);
  const containerRef1 = useRef<HTMLDivElement | null>(null);
  const [containerRef2, bounds] = useMeasure({ polyfill: ResizeObserver });
  const boundsValid = bounds.height > 0;
  // Merge the refs so they can attach to the same element
  const containerRef = useCallback(
    (el: HTMLDivElement) => {
      containerRef1.current = el;
      containerRef2(el);
    },
    [containerRef1, containerRef2]
  );
  const [messages, setMessages] = React.useState<IMessage[]>([]);
  const [oldParticipants, setOldParticipants] = React.useState<
    {
      displayName: string;
      userId: string;
    }[]
  >(
    Array.from(participants.keys()).map((x) => ({
      displayName: x.rawDisplayName,
      userId: x.userId,
    }))
  );
  const [newMessage, setNewMessage] = React.useState<boolean>(false);
  const [showChat, setShowChat] = React.useState<boolean>(false);
  const inputFile = useRef<HTMLInputElement | null>(null);
  const [datachannels, setDatachannels] = React.useState<
    Map<string, RTCDataChannel>
  >(new Map());
  const [fileDataChannels, setFileDataChannels] = React.useState<
    Map<string, RTCDataChannel>
  >(new Map());
  const [fileBuffers, setFileBuffers] = React.useState<
    Map<string, IFileMessage>
  >(new Map());
  const { layout, setLayout } = useVideoGridLayout(screenshareFeeds.length > 0);
  const { toggleFullscreen, fullscreenParticipant } =
    useFullscreen(containerRef1);

  const [spatialAudio] = useSpatialAudio();

  const [audioContext, audioDestination] = useAudioContext();
  const [showInspector] = useShowInspector();
  const { modalState: feedbackModalState, modalProps: feedbackModalProps } =
    useModalTriggerState();

  const { hideScreensharing } = useUrlParams();
  useEffect(() => {
    const dataChannelListener = (channel: RTCDataChannel) => {
      if (channel.label.includes("TEXT")) {
        if (!datachannels.has(channel.label)) {
          channel.onmessage = (ev) => {
            let message;
            const data = JSON.parse(ev.data);
            message = {
              type: MessageType.text,
              id: ev.lastEventId,
              text: data.body,
              image: data.url ?? undefined,
              user: {
                id: data.sender?.userId,
                name: data.sender?.name,
              },
              created_at: data.created_at,
            };
            setMessages((prev) => [...prev, message]);
            setNewMessage(true);
          };
          setDatachannels((prev) => prev.set(channel.label, channel));
        }
      } else {
        if (!fileDataChannels.has(channel.label)) {
          channel.onmessage = (ev) => {
            let message;
            try {
              const messageObject = JSON.parse(ev.data);
              setFileBuffers((prev) =>
                prev.set(channel.label, {
                  ...messageObject,
                  size: 0,
                  arrayBuffer: [],
                })
              );
            } catch (e) {
              const fileMessage = fileBuffers.get(channel.label);
              if (
                fileMessage != undefined &&
                fileMessage.info.size === fileMessage.size + ev.data.byteLength
              ) {
                const file = new Blob([...fileMessage.arrayBuffer, ev.data]);
                message = {
                  type: fileMessage.msgtype,
                  id: fileMessage.fileId,
                  text: `${fileMessage.body} (${Math.round(
                    fileMessage.info.size / 1000
                  )} KB)`,
                  image:
                    fileMessage.msgtype === MessageType.image
                      ? URL.createObjectURL(file)
                      : undefined,
                  user: {
                    id: fileMessage.sender.userId,
                    name: fileMessage.sender.name,
                  },
                  created_at: fileMessage.created_at,
                  onDownloadClick: () =>
                    downloadURI(URL.createObjectURL(file), fileMessage.body),
                };
                setFileBuffers((prev) => {
                  prev.delete(channel.label);
                  return prev;
                });
              } else if (fileMessage != undefined) {
                const newArrayBuffer = fileMessage.arrayBuffer;
                newArrayBuffer.push(ev.data);
                setFileBuffers((prev) =>
                  prev.set(channel.label, {
                    ...fileMessage,
                    size: fileMessage.size + ev.data.byteLength,
                    arrayBuffer: newArrayBuffer,
                  })
                );
              }
            }
            if (message != undefined) {
              setMessages((prev) => [...prev, message]);
            }
          };
          setFileDataChannels((prev) => prev.set(channel.label, channel));
        }
      }
    };
    const callListener = (event: CallsByUserAndDevice) => {
      groupCall.forEachCall((call) => {
        if (
          call.peerConn != undefined &&
          !fileDataChannels.has(`FILES-${call.callId}`)
        ) {
          call.createDataChannel(`FILES-${call.callId}`, undefined);
        }
        if (
          call.peerConn != undefined &&
          !datachannels.has(`TEXT-${call.callId}`)
        ) {
          call.createDataChannel(`TEXT-${call.callId}`, undefined);
        }
      });
    };
    groupCall.on(CallEvent.DataChannel, dataChannelListener);
    groupCall.on(GroupCallEvent.CallsChanged, callListener);
    return () => {
      groupCall.removeListener(GroupCallEvent.CallsChanged, callListener);
      groupCall.removeListener(CallEvent.DataChannel, dataChannelListener);
    };
  }, []);
  useEffect(() => {
    widget?.api.transport.send(
      layout === "freedom"
        ? ElementWidgetActions.TileLayout
        : ElementWidgetActions.SpotlightLayout,
      {}
    );
  }, [layout]);

  useEffect(() => {
    if (widget) {
      const onTileLayout = async (ev: CustomEvent<IWidgetApiRequest>) => {
        setLayout("freedom");
        await widget.api.transport.reply(ev.detail, {});
      };
      const onSpotlightLayout = async (ev: CustomEvent<IWidgetApiRequest>) => {
        setLayout("spotlight");
        await widget.api.transport.reply(ev.detail, {});
      };

      widget.lazyActions.on(ElementWidgetActions.TileLayout, onTileLayout);
      widget.lazyActions.on(
        ElementWidgetActions.SpotlightLayout,
        onSpotlightLayout
      );

      return () => {
        widget.lazyActions.off(ElementWidgetActions.TileLayout, onTileLayout);
        widget.lazyActions.off(
          ElementWidgetActions.SpotlightLayout,
          onSpotlightLayout
        );
      };
    }
  }, [setLayout]);

  useEffect(() => {
    const newParticipants = Array.from(participants.keys()).map((x) => ({
      displayName: x.rawDisplayName,
      userId: x.userId,
    }));
    const removed = oldParticipants.find(
      (x) => !newParticipants.some((y) => y.userId === x.userId)
    );
    let message:
      | { type: string; participant: { displayName: string; userId: string } }
      | undefined = undefined;
    if (removed != undefined) {
      message = {
        type: "participant_left",
        participant: removed,
      };
      try {
        window.parent.postMessage(message);
      } catch (e) {}
    } else {
      const added = newParticipants.find(
        (x) => !oldParticipants.some((y) => y.userId === x.userId)
      );
      if (added != undefined) {
        message = {
          type: "participant_joined",
          participant: added,
        };
      }
    }
    if (message != undefined) {
      setOldParticipants(newParticipants);
      try {
        window.parent.postMessage(message, "*");
      } catch (e) {
        console.log(e);
      }
    }
  }, [participants]);

  const items = useMemo(() => {
    const tileDescriptors: TileDescriptor[] = [];
    const localUserId = client.getUserId()!;

    const localDeviceId = client.getDeviceId()!;

    // One tile for each participant, to start with (we want a tile for everyone we
    // think should be in the call, even if we don't have a call feed for them yet)
    for (const [member, participantMap] of participants) {
      for (const [deviceId, { connectionState, presenter }] of participantMap) {
        const callFeed = userMediaFeeds.find(
          (f) => f.userId === member.userId && f.deviceId === deviceId
        );

        tileDescriptors.push({
          id: `${member.userId} ${deviceId}`,
          member,
          callFeed,
          focused: screenshareFeeds.length === 0 && callFeed === activeSpeaker,
          isLocal: member.userId === localUserId && deviceId === localDeviceId,
          presenter,
          connectionState,
        });
      }
    }

    PosthogAnalytics.instance.eventCallEnded.cacheParticipantCountChanged(
      tileDescriptors.length
    );

    // Add the screenshares too
    for (const screenshareFeed of screenshareFeeds) {
      const member = screenshareFeed.getMember()!;
      const connectionState = participants
        .get(member)
        ?.get(screenshareFeed.deviceId!)?.connectionState;

      // If the participant has left, their screenshare feed is stale and we
      // shouldn't bother showing it
      if (connectionState !== undefined) {
        tileDescriptors.push({
          id: screenshareFeed.stream.id,
          member,
          callFeed: screenshareFeed,
          focused: true,
          isLocal: screenshareFeed.isLocal(),
          presenter: false,
          connectionState,
        });
      }
    }

    return tileDescriptors;
  }, [client, participants, userMediaFeeds, activeSpeaker, screenshareFeeds]);

  const reducedControls = boundsValid && bounds.width <= 400;
  const noControls = reducedControls && bounds.height <= 400;

  // The maximised participant: either the participant that the user has
  // manually put in fullscreen, or the focused (active) participant if the
  // window is too small to show everyone
  const maximisedParticipant = useMemo(
    () =>
      fullscreenParticipant ??
      (noControls
        ? items.find((item) => item.focused) ??
          items.find((item) => item.callFeed) ??
          null
        : null),
    [fullscreenParticipant, noControls, items]
  );

  const renderAvatar = useCallback(
    (roomMember: RoomMember, width: number, height: number) => {
      const avatarUrl = roomMember.getMxcAvatarUrl();
      const size = Math.round(Math.min(width, height) / 2);

      return (
        <Avatar
          key={roomMember.userId}
          size={size}
          src={avatarUrl ?? undefined}
          fallback={roomMember.name.slice(0, 1).toUpperCase()}
          className={styles.avatar}
        />
      );
    },
    []
  );

  const [newGrid] = useNewGrid();
  const Grid = newGrid ? NewVideoGrid : VideoGrid;
  const prefersReducedMotion = usePrefersReducedMotion();
  const mobile = useCheckIsMobile();
  const renderContent = (): JSX.Element => {
    if (showChat && mobile) {
      return (
        <div className={styles.chatContainer}>
          <MessageContainer
            themeColor={"#ffd140"}
            showBack={false}
            onSendMessage={(message) => sendMessage(message)}
            messages={messages}
            currentUserId={groupCall.room.myUserId}
            onAttachClick={() => inputFile?.current?.click()}
          ></MessageContainer>
          <input
            onChange={onChangeFile}
            type="file"
            id="file"
            ref={inputFile}
            style={{ display: "none" }}
          />
        </div>
      );
    }
    if (items.length === 0) {
      return (
        <div className={styles.centerMessage}>
          <p>{t("Waiting for other participants…")}</p>
        </div>
      );
    }
    if (maximisedParticipant) {
      return (
        <VideoTileContainer
          targetHeight={bounds.height}
          targetWidth={bounds.width}
          key={maximisedParticipant.id}
          item={maximisedParticipant}
          getAvatar={renderAvatar}
          audioContext={audioContext}
          audioDestination={audioDestination}
          disableSpeakingIndicator={true}
          maximised={Boolean(maximisedParticipant)}
          fullscreen={maximisedParticipant === fullscreenParticipant}
          onFullscreen={toggleFullscreen}
        />
      );
    }

    return (
      <Grid
        items={items}
        layout={layout}
        disableAnimations={prefersReducedMotion || isSafari}
      >
        {({ item, ...rest }: ChildrenProperties) => (
          <VideoTileContainer
            item={item}
            getAvatar={renderAvatar}
            audioContext={audioContext}
            audioDestination={audioDestination}
            disableSpeakingIndicator={items.length < 3}
            maximised={false}
            fullscreen={false}
            onFullscreen={toggleFullscreen}
            {...rest}
          />
        )}
      </Grid>
    );
  };
  const {
    modalState: rageshakeRequestModalState,
    modalProps: rageshakeRequestModalProps,
  } = useRageshakeRequestModal(groupCall.room.roomId);

  const containerClasses = classNames(styles.inRoom, {
    [styles.maximised]: maximisedParticipant,
  });
  // If spatial audio is disabled, we render one audio tag for each participant
  // (with spatial audio, all the audio goes via the Web Audio API)
  // We also do this if there's a feed maximised because we only trigger spatial
  // audio rendering for feeds that we're displaying, which will need to be fixed
  // once we start having more participants than we can fit on a screen, but this
  // is a workaround for now.
  const { audioOutput } = useMediaHandler();
  const audioElements: JSX.Element[] = [];
  if (!spatialAudio || maximisedParticipant) {
    for (const item of items) {
      audioElements.push(
        <AudioSink
          tileDescriptor={item}
          audioOutput={audioOutput}
          otelGroupCallMembership={otelGroupCallMembership}
          key={item.id}
        />
      );
    }
  }

  let footer: JSX.Element | null;

  if (noControls) {
    footer = null;
  } else {
    const buttons: JSX.Element[] = [];

    buttons.push(
      <MicButton
        key="1"
        muted={microphoneMuted}
        onPress={toggleMicrophoneMuted}
        data-testid="incall_mute"
      />,
      <VideoButton
        key="2"
        muted={localVideoMuted}
        onPress={toggleLocalVideoMuted}
        data-testid="incall_videomute"
      />,
      <ChatButton
        key="2.5"
        showChat={showChat}
        newMessage={newMessage}
        onPress={() => {
          setNewMessage(false);
          setShowChat(!showChat);
        }}
        data-testid="incall_showchat"
      />
    );

    if (!reducedControls) {
      if (canScreenshare && !hideScreensharing && !isSafari) {
        buttons.push(
          <ScreenshareButton
            key="3"
            enabled={isScreensharing}
            onPress={toggleScreensharing}
            data-testid="incall_screenshare"
          />
        );
      }
      if (!maximisedParticipant) {
        buttons.push(
          <OverflowMenu
            key="4"
            inCall
            roomIdOrAlias={roomIdOrAlias}
            groupCall={groupCall}
            showInvite={joinRule === JoinRule.Public}
            feedbackModalState={feedbackModalState}
            feedbackModalProps={feedbackModalProps}
          />
        );
      }
    }

    buttons.push(<HangupButton key="6" onPress={onLeave} />);
    footer = <div className={styles.footer}>{buttons}</div>;
  }

  const downloadURI = (uri, name) => {
    var link = document.createElement("a");
    link.download = name;
    link.href = uri;
    link.click();
  };

  const onChangeFile = async (event) => {
    event.stopPropagation();
    event.preventDefault();
    var file = event.target.files[0];
    const userId = client.getUserId();
    const reader = new FileReader();
    const chunkSize = 16384;
    const fileId = uuidv4();
    let offset = 0;
    const createdAt = new Date().toLocaleTimeString();
    const fileMessage =
      file.type === "image/jpeg" || file.type === "image/png"
        ? {
            msgtype: MessageType.image,
            id: fileId,
            body: file.name,
            info: {
              size: file.size,
              mimetype: file.type,
            },
            sender: {
              userId: userId,
              name:
                userId != undefined
                  ? client.getUser(userId)?.rawDisplayName
                  : undefined,
            },
            created_at: createdAt,
          }
        : {
            msgtype: MessageType.file,
            body: file.name,
            id: fileId,
            info: {
              size: file.size,
              mimetype: file.type,
            },
            sender: {
              userId: userId,
              name:
                userId != undefined
                  ? client.getUser(userId)?.rawDisplayName
                  : undefined,
            },
            created_at: createdAt,
          };
    fileDataChannels.forEach((channel) => {
      if (
        channel.readyState === "open" ||
        channel.readyState === "connecting"
      ) {
        channel.send(JSON.stringify(fileMessage));
      }
    });
    reader.addEventListener("load", (e) => {
      fileDataChannels.forEach((channel) => {
        if (
          channel.readyState === "open" ||
          channel.readyState === "connecting"
        ) {
          channel.send(e.target?.result as ArrayBuffer);
        }
      });
      offset += e.target?.result?.byteLength;
      if (offset < file.size) {
        readSlice(offset);
      }
    });
    const readSlice = (o) => {
      const slice = file.slice(offset, o + chunkSize);
      reader.readAsArrayBuffer(slice);
    };
    readSlice(0);
    const text = `${file.name} (${Math.round(file.size / 1000)} KB)`;
    const type =
      file.type === "image/jpeg" || file.type === "image/png"
        ? MessageType.image
        : MessageType.file;
    setMessages((prev) => [
      ...prev,
      {
        type: type,
        id: fileId,
        text: text,
        image:
          type === MessageType.image ? URL.createObjectURL(file) : undefined,
        user: {
          id: userId ?? undefined,
          name:
            userId != undefined
              ? client.getUser(userId)?.rawDisplayName
              : undefined,
        },
        created_at: createdAt,
        onDownloadClick: () =>
          downloadURI(URL.createObjectURL(file), file.name),
      },
    ]);
  };
  const sendMessage = (message: string) => {
    const userId = client.getUserId();
    const createdAt = new Date().toLocaleTimeString();
    datachannels.forEach((channel) => {
      if (
        channel.readyState === "open" ||
        channel.readyState === "connecting"
      ) {
        channel.send(
          JSON.stringify({
            msgtype: MessageType.text,
            body: message,
            sender: {
              userId: userId,
              name:
                userId != undefined
                  ? client.getUser(userId)?.rawDisplayName
                  : undefined,
            },
            created_at: createdAt,
          })
        );
      }
    });
    setMessages((prev) => [
      ...prev,
      {
        type: MessageType.text,
        id: createdAt,
        text: message,
        image: undefined,
        user: {
          id: userId ?? undefined,
          name:
            userId != undefined
              ? client.getUser(userId)?.displayName
              : undefined,
        },
        created_at: createdAt,
      },
    ]);
  };
  return (
    <div className={containerClasses} ref={containerRef}>
      <>{audioElements}</>
      {!hideHeader && !maximisedParticipant && (
        <Header>
          <LeftNav>
            <RoomHeaderInfo roomName={roomName} avatarUrl={avatarUrl} />
            <VersionMismatchWarning
              users={unencryptedEventsFromUsers}
              room={groupCall.room}
            />
          </LeftNav>
          {/*<RightNav>*/}
          {/*  <GridLayoutMenu layout={layout} setLayout={setLayout} />*/}
          {/*  <UserMenuContainer preventNavigation />*/}
          {/*</RightNav>*/}
        </Header>
      )}
      <div className={styles.controlsOverlay}>
        {renderContent()}
        {!mobile && showChat && (
          <div className={styles.chatContainer}>
            <MessageContainer
              themeColor={"#ffd140"}
              showBack={false}
              onSendMessage={(message) => sendMessage(message)}
              messages={messages}
              currentUserId={groupCall.room.myUserId}
              onAttachClick={() => inputFile?.current?.click()}
            ></MessageContainer>
            <input
              onChange={onChangeFile}
              type="file"
              id="file"
              ref={inputFile}
              style={{ display: "none" }}
            />
          </div>
        )}
        {footer}
      </div>
      <GroupCallInspector
        client={client}
        groupCall={groupCall}
        otelGroupCallMembership={otelGroupCallMembership}
        show={showInspector}
      />
      {rageshakeRequestModalState.isOpen && (
        <RageshakeRequestModal
          {...rageshakeRequestModalProps}
          roomIdOrAlias={roomIdOrAlias}
        />
      )}
    </div>
  );
}

interface IFileMessage {
  readonly fileId: string;
  readonly arrayBuffer: ArrayBuffer[];
  readonly info: {
    size: number;
    mimetype: string;
  };
  readonly size: number;

  readonly sender: {
    readonly userId: string;
    readonly name: string;
  };

  readonly body: string;
  readonly msgtype: MessageType;
  readonly created_at: string;
}
