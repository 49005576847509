import {IUser} from "./IUser"

export interface IMessage {
    user: IUser;
    id?: string;
    text?: string;
    image?: string;
    url?: string;
    created_at?: string;
    seen?: boolean;
    type: MessageType;
    onDownloadClick?: () => void;
}

export enum MessageType {
    file = "file",
    image= "image",
    text = "text",
}
